html,
body,
#root {
  width: 100%;
  height: 100%;
  min-width: 40rem;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Obviously-Regular";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Obviously-Regular"), url("./theme/fonts/Obviously-Regular.woff2"),
    format("woff2");
}
